import React from "react";
import {FaRegTrashAlt, FaExchangeAlt} from 'react-icons/fa';

const style = {
    li: `flex justify-between bg-green-500 p-4 my-2 capitalize rounded-md`,
    liUnregistred: `flex justify-between bg-slate-400 p-4 my-2 capitalize rounded-md`,
    liSub: `flex justify-between bg-yellow-500 p-4 my-2 capitalize rounded-md`,
    row: `flex`,
    name: `text-green-50 font-bold`,
    nameUnregistred: `ml-1 text-slate-200 line-through`,
    nameSub: `text-yellow-50`,
    number: `bg-green-50 text-green-500 px-2 mr-2 rounded-full`,
    numberSub: `bg-yellow-100 text-yellow-500 px-2 mr-2 rounded-full`,
    buttonChange: `cursor-pointer text-neutral-50 ml-4`,
    buttonSuppr: `cursor-pointer text-red-600 ml-4`
}

const Player = ({index, player, admin, toggleRegistered, deletePlayer}) => {
    return (
        <li className={ (player.registered && index < 10)  ? style.li : player.registered ? style.liSub : style.liUnregistred }>
            <div className={style.row}>
                <p className={ (player.registered && index < 10)  ? style.name : player.registered ? style.nameSub : style.nameUnregistred }>
                    {!player.registered ? null : (
                        <span className={ (player.registered && index < 10)  ? style.number : style.numberSub }>{index+1}</span>
                    )}
                    {player.name}
                </p>
            </div>
            <div>
            {!admin ? null : (
                <button className={style.buttonChange} onClick={()  => toggleRegistered(player)}><FaExchangeAlt /></button>
            )}
            {!admin ? null : (
                <button className={style.buttonSuppr} onClick={()  => deletePlayer(player.id)}><FaRegTrashAlt /></button>
            )}
            </div>
        </li>
    )
}

export default Player